<template>
  <article
    class="card"
    v-if="!item.youtube"
    :class="[additionalClasses ? 'card--' + additionalClasses : '', cardFeatured ? 'card--featured' : '']">
    <a :href="item.url" class="card__link" :class="cardFeatured ? 'card__link--featured' : null">
      <div class="card__image">
        <picture class="card__image-container">
          <source media="(max-width: 599px)" :srcset="item.responsiveImage" v-if="item.responsiveImage">
          <source media="(min-width: 600px)" :srcset="item.mainImage">

          <img class="card__image-asset" :src="item.mainImage" alt="">
        </picture>
      </div>

      <div class="card__content">
        <div class="card__cat"><span v-for="cat in item.postCategory">{{ cat }}</span></div>

        <h4 class="card__title">{{ item.title }}</h4>

        <p class="card__excerpt">{{ item.excerpt | truncate(70) }}</p>
      </div>
    </a>
  </article>

  <article class="card card--video card--featured"
           v-else
           :class="{'isPlaying': video.isPlaying}">
    <div class="card__image">
      <picture class="card__image-container" v-on:click="toggleEmbed">
        <img class="card__image-asset"
             :src="item.youtube.posterImage ? item.youtube.posterImage : 'https://img.youtube.com/vi/' + item.youtube.id + '/maxresdefault.jpg'"
             alt="">
      </picture>

      <i class="card__fullscreen" v-on:click="fullscreenPlayer"></i>
    </div>

    <iframe class="card__video-player"
            ref="videoPlayer"
            :data-src="'https://www.youtube.com/embed/' + item.youtube.id + '?enablejsapi=1&autoplay=1&controls=0&modestbranding=1&iv_load_policy=3&disablekb=1&playsinline=1&origin=' + origin + '&muted=1'"
            width="560" height="315"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="1"></iframe>
  </article>
</template>

<script>
  export default {
    filters: {
      truncate: function (value, limit) {
        if (!value) {
          return
        }

        if (value.length > limit) {
          value = value.substring(0, (limit - 3)) + '...';
        }

        return value
      }
    },
    props: {
      additionalClasses: {
        type: String
      },
      cardFeatured: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object
      }
    },
    data() {
      return {
        origin: window.location.origin,
        video: {
          isPlaying: false,
          isLoaded: false
        }
      }
    },
    methods: {
      toggleEmbed: function () {
        if (!this.video.isLoaded) {
          this.$refs.videoPlayer.setAttribute('src', this.$refs.videoPlayer.getAttribute('data-src'));

          this.video.isLoaded = true;
        }

        if (!this.video.isPlaying) {
          this.$refs.videoPlayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

          this.video.isPlaying = true;
        } else {
          this.$refs.videoPlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');

          this.video.isPlaying = false;
        }
      },
      fullscreenPlayer: function () {
        // const requestFullscreen = this.$refs.videoPlayer.requestFullscreen || this.$refs.videoPlayer.mozRequestFullScreen || this.$refs.videoPlayer.webkitRequestFullScreen;

        this.$refs.videoPlayer.requestFullscreen();
        // console.log('JEBOTE', requestFullscreen);
        //
        // if (requestFullscreen) {
        //   requestFullscreen.bind(this.$refs.videoPlayer);
        // }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vendor/_mappy-breakpoints.scss';
@import '@/styles/abstracts/_functions.scss';
@import '@/styles/abstracts/_mixins.scss';
@import '@/styles/abstracts/_variables.scss';

.card {
  $this: &;
  position: relative;
  display: flex;
  width: 100%;

  @include query(mobile) {
    .card-list__item--featured-primary &,
    .card-list__item--featured-secondary & {
      height: 0;
      padding-top: percentage(540/329);
    }
  }
  @include query(tablet) {
    .card-list__item--featured-primary &,
    .card-list__item--featured-secondary & {
      height: 400px;
    }
  }
  @include query(tablet-up) {
    .card-list__item--featured-primary & {
      height: 0;
      padding-top: percentage(478/770);
    }
    .card-list__item--featured-secondary & {
      height: 0;
      padding-top: percentage(400/1170);
    }
  }

  &:not(.card--video):hover {
    #{$this}__image {
      opacity: 0.8;
    }
  }

  &--video:hover {
    #{$this}__image {
      opacity: 0.95;
    }
  }

  &--featured {
    #{$this}__link {
      width: 100%;
      height: 100%;

      justify-content: flex-end;

      position: absolute;
      top: 0;
      left: 0;
    }

    #{$this}__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    #{$this}__image-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    #{$this}__content {
      color: color('white');
      margin-bottom: rem(30);
      margin-left: rem(25);
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__image {
    line-height: 0;
    min-height: rem(1);
    transition: opacity .3s ease;
  }

  &__image-container {
    min-height: rem(1);
    display: block;
  }

  &__image-asset {
    display: block;
    width: 100%;
    height: auto;

  }

  &--video {
    #{$this}__image {
      cursor: pointer;
      z-index: 10;

      &:after {
        width: 100px;
        height: 100px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        background-image: url(../assets/icons/play.svg);
        background-size: contain;
        content: "";
        transition: transform 0.3s ease;
        pointer-events: none;
      }

      &:hover {
        &:after {
          transform: translate(-50%,-50%) scale(1.25);
        }
      }
    }
    #{$this}__image-container {
      transition: opacity 0.2s ease;
    }
    #{$this}__image-asset {
      min-width: 100%;
      width: auto;
      height: 100%;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate3d(-50%, -50%, 0);
    }

    #{$this}__video-player {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;

      opacity: 0;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 0.2s ease;
    }

    #{$this}__fullscreen {
      width: 20px;
      height: 20px;

      position: absolute;
      top: auto;
      left: auto;
      right: 30px;
      bottom: 30px;

      background-image: url(../assets/icons/fullscreen.svg);
      background-size: contain;
      opacity: 0;
      transition: 0.2s ease;
      transition-property: opacity, transform;

      &:hover {
        transform: scale(1.25);
      }
    }

    &.isPlaying {
      #{$this}__image {
        &:after {
          width: 20px;
          height: 20px;
          right: 49px;
          left: auto;
          bottom: 20px;
          top: auto;
          background-image: url(../assets/icons/pause.svg);
        }
      }
      #{$this}__image-container {
        opacity: 0;
      }
      #{$this}__video-player,
      #{$this}__fullscreen {
        opacity: 1;
      }
    }
  }

  &__content {
    max-width: percentage(3/4);
    margin-top: rem(32);
    color: color('text-black-light');
    z-index: z('content');
  }

  &__cat {
    @include font-size('card-category');
    letter-spacing: rem(1);
  }

  &__title {
    @include font-size('h4');
    margin-top: rem(24);
    letter-spacing: rem(1);
  }

  &__excerpt {
    margin-top: rem(23);
    opacity: 0.5;
  }
}
</style>
