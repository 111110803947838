import L from 'leaflet';

export default class {
  constructor() {
    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    const mapElement = document.querySelector('.js-map');

    if (!mapElement) {
      return;
    }

    const map = L.map('map', {
      center: [mapElement.dataset.latitude, mapElement.dataset.longitude],
      zoom: 18,
      scrollWheelZoom: false
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(map);

    const customIcon = L.icon({
      iconUrl:       '/dist/assets/images/marker-icon.png',
      iconRetinaUrl: '/dist/assets/images/marker-icon-2x.png',
      shadowUrl:     '/dist/assets/images/marker-shadow.png'
    });

    // show a marker on the map
    L.marker({lat: mapElement.dataset.latitude, lon: mapElement.dataset.longitude}, {icon: customIcon}).addTo(map);
  }
}
