import './scripts/polyfill';
import Vue from "vue";
import Components from "./components";
import ScrollTo from './scripts/scrollTo';
import Map from './scripts/map';
import ContactForm from './scripts/form';
import "./styles/main.scss";

Vue.config.productionTip = false;

if (document.getElementById('app')) {
  new Vue({
    el: "#app",
    delimiters: ["${", "}"],
  });
}

window.addEventListener('DOMContentLoaded', () => {
  new Map();
  new ContactForm();
  new ScrollTo();
});
