export default class {
  constructor() {
    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    const scrollToElements = document.querySelectorAll('.js-scrollTo');
    const currentLocation = window.location;

    if (scrollToElements.length < 1 || currentLocation.pathname !== '/') {
      return;
    }

    scrollToElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();

        const targetId = element.getAttribute('href').replace(/^\/\#/, '');

        document.getElementById(targetId).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }
}
