<template>
  <div class="posts-filter-section">
    <div class="card-list">
      <div class="card-list__filter-wrapper">
        <div class="card-list__filter-holder" ref="filterHolder" v-on:scroll="handleFilterScroll">
          <div class="card-list__filter-btn">
            <input class="card-list__filter-element"
                   type="radio"
                   v-model="selectedFilter"
                   @change="postFiltering"
                   value="0"
                   id="chk-0"/>

            <label class="card-list__filter-label" for="chk-0">Alle</label>
          </div>

          <div class="card-list__filter-btn" v-for="(cat, index) in cats">
            <input class="card-list__filter-element"
                   type="radio"
                   v-model="selectedFilter"
                   @change="postFiltering"
                   :value="cat.id"
                   :id="'chk-' + (index + 1)"/>

            <label class="card-list__filter-label" :for="'chk-' + (index + 1)">{{ cat.title }}</label>
          </div>
        </div>
      </div>

      <div class="card-list__wrapper">
        <transition-group name="fade-in-out" tag="div" class="card-list__holder">
          <div
            class="card-list__item"
            :class="[
              (article.featured === 'primary') ? 'card-list__item--featured-primary' : '',
              (article.featured === 'secondary') ? 'card-list__item--featured-secondary' : '',
            ]"
            v-for="(article, index) in filteredPosts"
            :key="index + 0">
            <card
              :item="article"
              :cardFeatured="article.featured !== null">
            </card>
          </div>
        </transition-group>
        <div class="card-list__load-more">
          <button
            v-if="totalPosts !== offset"
            class="button button--primary"
            @click="loadMore">
            MEHR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Card from '@/components/Card';

  export default {
    components: {
      Card,
    },
    data() {
      return {
        selectedFilter: 0,
        cats: [],
        posts: [],
        offset: 0,
        totalPosts: 0
      }
    },
    methods: {
      getPosts(catId, offset, reset = false) {
        axios.get(`/api/articles/${catId}?offset=${offset}`).then(response => {
          if (reset) {
            this.posts = [];
          }
          if (response.data.data.length > 0) {
            this.posts.push(...response.data.data)
            this.offset = offset + response.data.data.length;
          }

          if (response.data.meta.hasOwnProperty('featured')) {
            const featuredPosts = response.data.meta.featured;
            for (let i = 0; i < featuredPosts.length; i++) {
              if (featuredPosts[i].featured === 'primary') {
                this.posts.unshift(featuredPosts[i])
              } else {
                this.posts.push(featuredPosts[i])
              }
            }
          }
          if (offset === 0) {
            this.totalPosts = response.data.meta.pagination.total;
          }

          if (window.location.hash === '#contact') {
            setTimeout(() => {
              document.getElementById('contact').scrollIntoView({
                behavior: 'smooth'
              });
            }, 0);
          }
        });
      },
      getCats() {
        axios.get(`/api/categories`).then(response => {
          if (response.data.data.length > 0) {
            this.cats.push(...response.data.data);

            setTimeout(() => {
              this.setupFilterScroll();
            }, 0);
          }
        });
      },
      postFiltering() {
        this.totalPosts = 0;
        const selected = this.selectedFilter;
        this.offset = 0;
        this.getPosts(selected, this.offset, true);
      },
      loadMore() {
        this.getPosts(this.selectedFilter, this.offset);
      },
      setupFilterScroll() {
        if (this.$refs.filterHolder.scrollWidth > this.$refs.filterHolder.clientWidth) {
          this.$refs.filterHolder.parentElement.classList.add('hasShadow', 'atStart');
        }
      },
      handleFilterScroll(event) {
        const filterContainer = event.target;
        const filterWrapper = filterContainer.parentElement;

        if (filterContainer.scrollLeft < 20) {
          filterWrapper.classList.add('atStart');
        } else {
          filterWrapper.classList.remove('atStart');
        }
        if (filterContainer.scrollLeft > (filterContainer.scrollWidth - filterContainer.clientWidth - 20)) {
          filterWrapper.classList.add('atEnd');
        } else {
          filterWrapper.classList.remove('atEnd');
        }
      }
    },
    computed: {
      filteredPosts() {
        return this.posts;

      }
    },
    created() {
      this.getCats();
      this.getPosts(this.selectedFilter, this.offset);
    }
  }
</script>

<style lang="scss" scoped>
  .fade-in-out-leave-active {
    transition: opacity .5s;
  }
  .fade-in-out-enter-active {
    transition: opacity .8s;
  }
  .fade-in-out-enter, .fade-in-out-leave-to {
    opacity: 0;
  }
</style>
