import axios from 'axios';

export default class {
  constructor() {
    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    const formElement = document.querySelector('.js-form');

    if (!formElement) {
      return;
    }

    const feedbackElement = formElement.querySelector('.js-feedback');

    formElement.addEventListener('submit', (event) => {
      event.preventDefault();

      let formData = Array.from(new FormData(formElement), e => e.map(encodeURIComponent).join('=')).join('&');

      axios
        .post('/', formData)
        .then(function (response) {
          if (response.data.success) {
            feedbackElement.innerHTML = '<li>Thank you, we\'ll get back to you!</li>';
            feedbackElement.classList.remove('form__feedback--errors');
            feedbackElement.classList.add('form__feedback--success');
            formElement.querySelector('.hasError').classList.remove('hasError');
          } else if (response.data.errors) {
            const errors = response.data.errors;

            Object.keys(errors).forEach((errorId) => {
              formElement.querySelector('#' + errorId).closest('.form__group').classList.add('hasError');

              errors[errorId].forEach((errorMessage) => {
                feedbackElement.innerHTML = '<li>' + errorMessage + '</li>'
              });
            });

            feedbackElement.classList.remove('form__feedback--success');
            feedbackElement.classList.add('form__feedback--errors');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }
}
